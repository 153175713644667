import { Connector } from "@web3-react/types";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { Web3AuthCore } from "@web3auth/core";
// import { ethers } from "ethers";
import { REACT_APP_WEB3_AUTH_ID, REACT_APP_MAINNET } from "../../utils/env";

function parseChainId(chainId) {
  return typeof chainId === "string" ? Number.parseInt(chainId, 16) : chainId;
}

/**
 * @param provider - An EIP-1193 ({@link https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1193.md}) provider.
 * @param onError - Handler to report errors thrown from eventListeners.
 */

export class BowlineWallet extends Connector {
  /** {@inheritdoc Connector.provider} */

  constructor({ actions, options, onError }) {
    super(actions, onError);
    this.chainId = options.chainId;
    this.chainHex = options.chainHex;
    this.initOptions = options.initOptions;
    this.loginOptions = options.loginOptions;
    this.loginProvider = options.loginProvider;
    this.clientId = REACT_APP_WEB3_AUTH_ID;
    this.userInfo = {};

    // this.provider = provider;
  }

  /** {@inheritdoc Connector.connectEagerly} */
  async connectEagerly() {
    const cancelActivation = this.actions.startActivation();

    // if (!this.web3auth) {
    //   await this.startWeb3Auth();
    // } else if (this.web3auth.status === "ready") {
    //   await this.startAdapter();
    // }

    // if (!this.provider && this.web3auth.status === "connected") {
    //   this.provider = this.web3auth.provider;
    // }

    try {
      return Promise.all([
        this.provider.request({ method: "eth_chainId" }),
        this.provider.request({ method: "eth_accounts" }),
      ])
        .then(([chainId, accounts]) => {
          this.actions.update({ chainId: parseChainId(chainId), accounts });
        })
        .catch((error) => {
          cancelActivation();
          this.actions.resetState();
          throw error;
        });
    } catch (error) {
      cancelActivation();
      this.actions.resetState();
      throw error;
    }
  }

  /** {@inheritdoc Connector.activate} */
  async activate() {
    const cancelActivation = this.actions.startActivation();

    if (!this.web3auth) {
      await this.startWeb3Auth();
    } else if (this.web3auth.status === "ready") {
      await this.startAdapter();
    }
    await this.startProvider();

    this.userInfo = await this.web3auth.getUserInfo();

    return Promise.all([
      this.provider.request({ method: "eth_chainId" }),
      this.provider
        .request({ method: "eth_requestAccounts" })
        .catch(() => this.provider.request({ method: "eth_accounts" })),
    ])
      .then(([chainId, accounts]) => {
        this.actions.update({ chainId: parseChainId(chainId), accounts });
      })
      .catch((error) => {
        cancelActivation();
        throw error;
      });
  }

  async deactivate() {
    console.log("deactivate");
    if (this.web3auth) {
      await this.web3auth.logout();
      this.web3auth = undefined;
    }
    if (this.provider) {
      // this.provider.removeAllListeners();
      this.provider = undefined;
    }
    this.userInfo = undefined;
    // this.chainId = undefined;
    // this.chainHex = undefined;
    // this.initOptions = undefined;
    // this.loginOptions = undefined;
    // this.loginProvider = undefined;
    this.actions.resetState();
  }

  async startWeb3Auth() {
    this.web3auth = new Web3AuthCore({
      clientId: this.clientId,
      chainConfig: this.initOptions,
    });

    await this.startAdapter();
  }

  async startAdapter() {
    const openloginAdapter = new OpenloginAdapter({
      adapterSettings: {
        clientId: this.clientId,
        network: REACT_APP_MAINNET ? "cyan" : "testnet",
        // uxMode: "redirect",
        loginConfig: this.loginOptions,
      },
    });
    this.web3auth.configureAdapter(openloginAdapter);
    this.adapter = openloginAdapter;
    const initializedWeb3Auth = await this.web3auth.init();
    console.log("initializedWeb3Auth", initializedWeb3Auth);
  }

  async startProvider() {
    if (this.web3auth && !this.provider) {
      if (this.web3auth.status === "connected") {
        this.provider = this.web3auth.provider;
      } else {
        this.provider = await this.web3auth.connectTo(this.adapter.name, {
          loginProvider: this.loginProvider,
        });
      }
    }
  }
}
