/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultShow,
  defaultShowFail,
  defaultUpdate,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateFail,
  defaultAddToDictionary,
  defaultSetListPage,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: { ...defaultInitialState, identifier_dictionary: {} },
  reducers: {
    list: defaultList,
    listSuccess: (state, action) => {
      const responseList = action.payload.obj.data || [];
      const responseIds = action.payload.obj.data.map((a) => a.id);
      const responseHeaders = action.payload.headers;
      state.listMeta = action.payload.obj.meta;
      if (responseHeaders && responseHeaders["total-count"])
        state.listTotalCount = parseInt(responseHeaders["total-count"], 10);
      if (responseHeaders && responseHeaders["current-page"])
        state.listPage = parseInt(responseHeaders["current-page"], 10);
      if (responseHeaders && responseHeaders["total-pages"])
        state.totalPages = parseInt(responseHeaders["total-pages"], 10);
      state.loadingList = false;
      state.loadedList = true;

      state.list = responseList;
      state.idsList = responseIds;

      responseList.forEach((entry) => (state.dictionary[entry.id] = entry));
      responseList.forEach(
        (entry) =>
          (state.identifier_dictionary[
            entry.attributes.contract_address +
              "/" +
              entry.attributes.identifier
          ] = entry.id)
      );
    },
    listFail: defaultListFail,
    setListPage: defaultSetListPage,
    show: defaultShow,
    showSuccess: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
    },
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: (state, action) => {
      state.creating = false;
      state.created = true;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
      state.createError = null;
    },
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: (state, action) => {
      state.updating = false;
      state.updated = true;
      state.show = action.payload;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
    },
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    refreshMetadata: (state) => {
      state.refreshingMetedata = true;
      state.refreshedMetedata = false;
      state.refreshingMetedataError = null;
    },
    refreshMetadataSuccess: (state) => {
      state.refreshingMetedata = false;
      state.refreshedMetedata = true;
      state.refreshingMetedataError = null;
    },
    refreshMetadataFail: (state, action) => {
      state.refreshingMetedata = false;
      state.refreshedMetedata = false;
      state.refreshingMetedataError = action.payload.message;
    },
    listUnlockables: (state) => {
      state.listingUnlockables = true;
      state.listedUnlockables = false;
      state.listingUnlockablesError = null;
    },
    listUnlockablesSuccess: (state, action) => {
      state.listingUnlockables = false;
      state.listedUnlockables = true;
      state.listingUnlockablesError = null;
      let entry = state.dictionary[action.payload.id];
      entry.relationships = {
        ...entry.relationships,
        ...action.payload.relationships,
      };
      state.dictionary[action.payload.id] = entry;
    },
    listUnlockablesFail: (state, action) => {
      state.listingUnlockables = false;
      state.listedUnlockables = false;
      state.listingUnlockablesError = action.payload.message;
    },
  },
});
export const {
  show: showToken,
  list: listTokens,
  create: createToken,
  listUnlockables,
  refreshMetadata,
  setListPage,
} = tokensSlice.actions;

export const actions = tokensSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const tokens = (state) =>
  state.tokens.idsList.map((id) => state.tokens.dictionary[id]);
export const token = (state) =>
  state.tokens.showId && state.tokens.dictionary[state.tokens.showId];
export const tokenAttributes = (state) => token(state)?.attributes;
export const tokenRelationships = (state) => token(state)?.relationships;
export const tokenId = (state) => state.tokens.showId;

export const getToken = createSelector(
  (state) => state.tokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getTokenAttributes = createSelector(
  (state) => state.tokens.dictionary,

  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getTokenIdByIdentifier = createSelector(
  (state) => state.tokens.identifier_dictionary,

  (_, options) => options,
  (identifier_dictionary, { contractAddress, identifier }) => {
    return (
      contractAddress &&
      identifier &&
      identifier_dictionary &&
      identifier_dictionary[contractAddress + "/" + identifier]
    );
  }
);

export const getTokenRelationships = createSelector(
  (state) => state.tokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getUnlockablesForToken = createSelector(
  (state) => state.tokens.dictionary,
  (state) => state.unlockBoundaries.dictionary,
  (_, id) => id,
  (dictionary, boundariesDictionary, id) => {
    let unlockables = [];
    const relationships = id && dictionary && dictionary[id]?.relationships;
    const boundaries = relationships?.unlock_boundaries?.data.map((k) => k.id) || [];
    boundaries.forEach((bou) => {
      unlockables.push(
        boundariesDictionary[bou].relationships?.unlockable?.data
      );
    });
    return unlockables;
  }
);

export const getTokenTraitValues = createSelector(
  (state) => state.tokens.dictionary,
  (state) => state.traits.dictionary,
  (state) => state.traitTypes.dictionary,
  (_, id) => id,
  (tokensDictionary, traitsDictionary, traitTypesDictionary, id) => {
    const traitObject = {};
    const traitIds =
      (id &&
        tokensDictionary &&
        tokensDictionary[id]?.relationships &&
        tokensDictionary[id]?.relationships?.traits &&
        tokensDictionary[id]?.relationships?.traits?.data?.map((k) => k.id)) ||
      [];

    traitIds.forEach((tid) => {
      const trait = traitsDictionary[tid];
      const traitTypeId = trait?.relationships?.trait_type?.data?.id;
      const traitType = traitTypesDictionary[traitTypeId];
      traitObject[traitType?.attributes?.name] = trait?.attributes?.value;
    });

    return traitObject;
  }
);

export const getTokens = createSelector(
  (state) => state.tokens.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default tokensSlice.reducer;
