import React, { useEffect } from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { bowlineWallet } from "../../connectors/googleWalletConnect";
// import { FcGoogle } from "react-icons/fc";

// import metamask from "../../assets/icons/metamask.svg";

const AuthConnectButton = (props, context) => {
  //props from web 3 must be instantiated wherever function is used

  // const name = getName(walletConnect);

  useEffect(() => {
    bowlineWallet.connectEagerly().catch(() => {
      console.debug("Failed to connect eagerly to walletconnect");
    });
  }, []);

  // async function connectWallet() {
  //   try {
  //     //activate wallet connection
  //     return await activate(web3AuthConnector);
  //   } catch (exception) {
  //     console.log("exception", exception);
  //     return exception;
  //   }
  // }

  const handleConnect = () => {
    bowlineWallet
      .activate(4)
      .then(() => console.log("connected"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="text-center">
      <p className="h4 text-center fw-bold mb-4">
        {context.t("What's Bowline Connect?")}
      </p>
      {/* <p className="h6 fw-bold my-2">
        {context.t("A new way to create your wallet")}
      </p> */}
      <p className="my-4 text-muted">
        {context.t(
          "Bowline Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp)."
        )}
      </p>
      <p className="mb-5 text-muted">
        {context.t(
          "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet."
        )}
      </p>
      <Button
        onClick={handleConnect}
        className="mb-3"
        size="lg"
        color="primary"
      >
        {context.t("Connect with Google")}
      </Button>
      <p className="small">
        {context.t(
          "by connecting you agree to the Bowline Connect Terms and Conditions"
        )}
      </p>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
AuthConnectButton.contextTypes = {
  t: PropTypes.func,
};

export default AuthConnectButton;
