import { initializeConnector } from "@web3-react/core";
import { BowlineWallet } from "./lib/bowlineWallet";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import {
  REACT_APP_INFURA_ID,
  REACT_APP_GOOGLE_ID,
  REACT_APP_GOOGLE_VERIFIER,
  REACT_APP_MAINNET,
} from "../utils/env";

export const [bowlineWallet, hooks] = initializeConnector(
  (actions) =>
    new BowlineWallet({
      actions,
      options: {
        chainId: REACT_APP_MAINNET ? 1 : 5,
        chainHex: REACT_APP_MAINNET ? "0x1" : "0x5",
        loginProvider: "google",
        initOptions: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: REACT_APP_MAINNET ? "0x1" : "0x5",
          rpcTarget:
            "https://" +
            (REACT_APP_MAINNET ? "mainnet" : "goerli") +
            ".infura.io/v3/" +
            REACT_APP_INFURA_ID,
        },
        loginOptions: {
          google: {
            name: "Bowline Connect Google",
            verifier: REACT_APP_GOOGLE_VERIFIER, // Please create a verifier on the developer dashboard and pass the name here
            typeOfLogin: "google", // Pass on the login provider of the verifier you've created
            clientId: REACT_APP_GOOGLE_ID, // Pass on the clientId of the login provider here - Please note this differs from the Web3Auth ClientID. This is the JWT Client ID
          },
        },
      },
    })
);
