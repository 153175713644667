/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery } from "redux-saga/effects";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";

export function* list(action) {
  let headers = yield buildHeaders();
  let {
    projectId,
    contract_address,
    pageNum,
    itemNum,
    sorting,
    filters,
    address,
  } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.MintTiers.listMintTiersForProject,
      {
        project_id: projectId,
        address: contract_address,
        page: pageNum,
        items: itemNum,
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* listTiersForProject(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum, sorting, filters, address } =
    action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.MintTiers.listMintTiersForProject,
      {
        project_id: projectId,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

/** Show Saga
 *  @description: connects to the showResourceName operation
 *  @param {number} action.payload the ResourceName id
 */
export function* show(action) {
  const { tierId, address, projectId } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.MintTiers.showMintTierForContract,
      {
        id: tierId,
        project_id: projectId,
        address: address,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}


/** Show Saga
 *  @description: connects to the showResourceName operation
 *  @param {number} action.payload the ResourceName id
 */
export function* generateWertSignature(action) {
  const { mint_tier_id, commodity_amount, contract_address, project_id, function_params, callbackFnc, function_abi } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.MintTiers.createWertSignature,
      {
        mint_tier_id,
        project_id,
        commodity_amount,
        contract_address,
        function_params,
        function_abi,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (callbackFnc) callbackFnc(payload.obj)
  } catch (e) {
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { address, project_id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.MintTiers.createMintTierForContract,
      { project_id: project_id, address: address },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

export function* update(action) {
  const { project_id, tierId, address, ...requestBody } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.MintTiers.updateMintTIersForContract,
      { project_id: project_id, id: tierId, address: address },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* mintTierSaga() {
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.generateWertSignature, generateWertSignature);
  yield takeEvery(actions.listTiersForProject, listTiersForProject);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
}
export default mintTierSaga;
