/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShowFail,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const contractSlice = createSlice({
  name: "contracts",
  initialState: {
    address: null,
    genericAbiReadValues: {},
    ...defaultInitialState,
    addressDictionary: {},
    showAddress: null,
  },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: (state, action) => {
      state.loadingShow = true;
      state.loadedShow = false;
      state.showError = null;
      state.show =
        state.addressDictionary[action.payload.contractAddress] || {};
      state.showId = action.payload || null;
      state.showAddress = action.payload.contractAddress;
    },
    showSuccess: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload;
      state.dictionary[action.payload.id] = action.payload;
      state.addressDictionary[action.payload.attributes?.address] =
        action.payload;
    },
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
    publicMint: (state, action) => {
      state.mintErrorMessage = null;
      state.mintError = false;
      state.mintingInProgress = false;
      state.publicMint = action.payload;
      state.awaitingTransactionConfirmation = true;
    },
    genericAbiWrite: (state, action) => {
      state.mintErrorMessage = null;
      state.mintError = false;
      state.mintingInProgress = false;
      state.mintSuccess = false;
      state.mintPending = false;
      state.awaitingTransactionConfirmation = true;
    },
    genericAbiWriteSuccess: (state, action) => {
      console.log("genericAbiWriteSuccess"); // TODO we should handle this for the alert I guess
      state.mintErrorMessage = null;
      state.mintError = false;
      state.mintingInProgress = false;
      state.mintSuccess = false;
      state.mintPending = true;
    },
    genericAbiWriteError: (state, action) => {
      console.log("genericAbiWriteError"); // TODO we should handle this for the alert I guess
      state.mintErrorMessage = action.payload;
      state.mintError = true;
      state.mintingInProgress = false;
      state.mintSuccess = false;
      state.mintPending = false;
    },

    genericAbiRead: (state, action) => {},
    genericAbiReadSuccess: (state, action) => {
      state.genericAbiReadValues[action.payload.abi.name] = action.payload.data;
    },
    genericAbiReadError: (state, action) => {
      console.log("genericAbiReadError"); // TODO we should handle this for the alert I guess
    },
    getTotalTokenAmount: (state, action) => {},
    getTotalTokenAmountSuccess: (state, action) => {
      if (action.payload) state.totalTokenAmount = action.payload;
    },
    transactionSuccess: (state, action) => {
      state.mintErrorMessage = null;
      state.mintError = false;
      state.mintingInProgress = false;
      state.mintSuccess = true;
      state.mintPending = false;
    },
    resetTransactionSuccess: (state, action) => {
      state.mintSuccess = false;
      state.mintError = false;
      state.mintingInProgress = false;
      state.mintPending = false;
    },
    whitelistMint: (state, action) => {
      state.mintErrorMessage = null;
      state.mintError = false;
      state.mintingInProgress = false;
      state.awaitingTransactionConfirmation = true;
      state.whitelistMint = action.payload;
    },
    freeMint: (state, action) => {
      state.mintErrorMessage = null;
      state.mintError = false;
      state.mintingInProgress = false;
      state.awaitingTransactionConfirmation = true;
      state.freeMint = action.payload;
    },
    mintingInProgressSuccess: (state, action) => {
      state.awaitingTransactionConfirmation = false;
      state.mintingInProgress = true;
    },
    mintError: (state, action) => {
      state.mintErrorMessage = action.payload;
      state.mintError = true;
      state.mintingInProgress = false;
      state.awaitingTransactionConfirmation = false;
    },
    mintErrorReset: (state, action) => {
      state.mintErrorMessage = null;
      state.mintError = false;
      state.mintingInProgress = false;
      state.awaitingTransactionConfirmation = false;
    },
    mintProgressReset: (state, action) => {
      state.mintingInProgress = false;
      state.awaitingTransactionConfirmation = false;
      state.mintPending = false;
    },
    loadContractState: (state, action) => {},
    loadContractStatePublicSuccess: (state, action) => {
      state.publicMintEnabled = action.payload.publicMintEnabled;
      state.publicMintPrice = action.payload.publicMintPrice;
      state.walletPublicMintLimit = action.payload.walletPublicMintLimit;
    },
    loadContractStateWhitelistSuccess: (state, action) => {
      state.whitelistMintEnabled = action.payload.whitelistMintEnabled;
      state.whitelistMintPrice = action.payload.whitelistMintPrice;
      state.walletWhitelistMintLimit = action.payload.walletWhitelistMintLimit;
    },
    checkMintingAvailability: (state, action) => {},
    checkMintingAvailabilitySuccess: (state, action) => {
      if (
        state.mintingInProgress &&
        (state.boughtAmounts !== action.payload.boughtAmounts ||
          state.boughtWhitelistAmounts !==
            action.payload.boughtWhitelistAmounts)
      ) {
        state.mintingInProgress = false;
        state.awaitingTransactionConfirmation = false;
      }

      state.boughtWhitelistAmounts = action.payload.boughtWhitelistAmounts;
      state.boughtAmounts = action.payload.boughtAmounts;
      state.totalMintedTokens = action.payload.totalMintedTokens;
    },
    checkMintpassRedemptions: (state, action) => {},
    checkMintpassRedemptionsSuccess: (state, action) => {
      if (
        state.mintingInProgress &&
        state.mintpassRedemptions[action.payload.signature] !==
          action.payload.amount
      ) {
        state.mintingInProgress = false;
        state.awaitingTransactionConfirmation = false;
      }
      state.mintpassRedemptions[action.payload.signature] =
        action.payload.amount;
    },
  },
});

export const {
  show: showContract,
  update: updateContract,
  list: listContracts,
  create: createContract,
  updateErrorReset: updateContractErrorReset,
  listErrorReset: listContractErrorReset,
  createErrorReset: createContractErrorReset,
  showErrorReset: showContractErrorReset,
  resetTransactionSuccess,
  genericAbiWrite,
  genericAbiRead,
  publicMint,
  freeMint,
  mintError,
  mintErrorReset,
  mintProgressReset,
  mintingInProgressSuccess,
  checkMintpassRedemptions,
  getTotalTokenAmount,
  checkMintingAvailability,
  loadContractState,
  transactionSuccess,
} = contractSlice.actions;
export const actions = contractSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const contracts = (state) =>
  state.contracts.idsList.map((id) => state.contracts.dictionary[id]);

export const contract = (state) => {
  return (
    state.contracts.showAddress &&
    state.contracts.addressDictionary[state.contracts.showAddress]
  );
};

export const getContract = createSelector(
  (state) => state.contracts.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getContractAttributes = createSelector(
  (state) => state.contracts.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getContractRelationships = createSelector(
  (state) => state.contracts.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getContracts = createSelector(
  (state) => state.contracts.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]?.attributes);
  }
);

export const getContractByAddress = createSelector(
  (state) => state.contracts.dictionary,
  (_, address) => address,
  (contractDictionary, address) => {
    let contractByAddress =
      contractDictionary &&
      address &&
      Object.keys(contractDictionary).find(
        (key) => contractDictionary[key]?.attributes?.address === address
      );
    return contractByAddress;
  }
);

export default contractSlice.reducer;
