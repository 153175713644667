/**
 *  Sagas for the voting resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { put, select } from "redux-saga/effects";
import { REACT_APP_PROJECT_ID } from "../utils/env";
import { push } from "connected-react-router";

/** Worker Sagas */
export function* handleUnauthorized(action) {
  if (action.payload?.status === 403) {
    const project = yield select(
      (state) => state.projects.dictionary[REACT_APP_PROJECT_ID]
    );
    const path = yield select((state) => state.router?.location?.pathname);
    console.log("path", path);
    if (path !== "/403") {
      yield put(
        push("403", {
          path,
          accessMode: project?.attributes?.access_mode,
          accessErrorCode: action.payload?.status,
        })
      );
    }
  }
}



/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* dictionarySagas() {
}
export default dictionarySagas;
