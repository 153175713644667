/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery } from "redux-saga/effects";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { actions as traitActions } from "../traits";

export function* list(action) {
  let headers = yield buildHeaders();
  let includedTraitsDictionary = {};
  let remainingIncluded = [];
  const { projectId, pageNum, itemNum, sorting, filters } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Tokens.listTokensForProject,
      {
        project_id: projectId,
        page: pageNum,
        items: itemNum,
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      payload.obj.included.forEach((entry) => {
        if (entry.type === "trait") {
          includedTraitsDictionary[entry.id] = entry;
        } else {
          remainingIncluded.push(entry);
        }
      });
    yield* storeIncluded({ payload: remainingIncluded });
    yield put({
      type: traitActions.mergeDictionary,
      payload: includedTraitsDictionary,
    });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, identifier, contractAddress } = action.payload;

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Tokens.showTokenForProjectContract,
      {
        project_id: projectId,
        contract_address: contractAddress,
        identifier: identifier,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { projectId, account, contractAddress, callbackFnc } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Tokens.createTokensForProjectContract,
      {
        project_id: projectId,
        contract_address: contractAddress,
      },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          wallet: { address: account },
        },
      }
    );

    yield storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
    if (callbackFnc) callbackFnc();
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

export function* refreshMetadata(action) {
  let headers = yield buildHeaders();
  const { projectId, identifier, contractAddress, callbackFnc } =
    action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Tokens.refreshMetadataForToken,
      {
        project_id: projectId,
        contract_address: contractAddress,
        identifier: identifier,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );

    yield storeIncluded({ payload: payload.obj.included });
    yield put({
      type: actions.refreshMetadataSuccess,
      payload: payload.obj.data,
    });
    if (callbackFnc) callbackFnc();
  } catch (e) {
    yield put({ type: actions.refreshMetadataFail, payload: e });
  }
}

export function* listUnlockables(action) {
  let headers = yield buildHeaders();
  const { projectId, identifier, contractAddress } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Tokens.listUnlockablesForToken,
      {
        project_id: projectId,
        contract_address: contractAddress,
        identifier: identifier,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    console.log("PAYLOAD", payload)
    yield storeIncluded({ payload: payload.obj.included });
    yield put({
      type: actions.listUnlockablesSuccess,
      payload: payload.obj.data,
    });
  } catch (e) {
    yield put({ type: actions.listUnlockablesFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* tokensSaga() {
  yield takeEvery(actions.refreshMetadata, refreshMetadata);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.listUnlockables, listUnlockables);
  yield takeEvery(actions.show, show);
}
export default tokensSaga;
