/**
 * The ConnectWallet component
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, Row, Col, NavItem, Nav } from "reactstrap";
import metamaskIcon from "../../assets/icons/metamask.svg";
// import bowlineIcon from "../../assets/icons/bowline.svg";
import walletconnectIcon from "../../assets/icons/walletconnect.svg";
import { FcGoogle } from "react-icons/fc";
// import { BsFacebook } from "react-icons/bs";

import MetamaskConnectInfo from "./utils/MetamaskConnectInfo";
// import BowlineWalletConnectInfo from "./utils/BowlineWalletConnectInfo";
import WalletConnectInfo from "./utils/WalletConnectInfo";
import AuthConnectButton from "../ConnectWalletButton/AuthConnectButton-component";
// import FacebookConnectButton from "../ConnectWalletButton/FacebookConnectButton-component";

/**
 * Stateless react component to render the ConnectWallet
 * @param {Object} props the props passed to the component.
 */

const ConnectWallet = (props, context) => {
  const { className, buttonId, buttonLabel, buttonColor, buttonSize, block } =
    props;
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState(0);

  return (
    <>
      <Button
        className={className}
        onClick={() => setShowModal(true)}
        color={buttonColor}
        size={buttonSize}
        id={buttonId}
        block={block}
      >
        {buttonLabel ? buttonLabel : context.t("Connect")}
      </Button>
      <Modal
        isOpen={showModal}
        size="xl"
        toggle={() => setShowModal(!showModal)}
        fullscreen="lg"
        centered
      >
        <ModalBody className="p-0 connect-modal-body">
          <button
            className="btn-close-dark btn-close position-absolute top-0 end-0 p-4"
            onClick={() => setShowModal(false)}
            aria-label={context.t("close")}
          ></button>
          <Row className="g-0 modal-row">
            <Col xs={{ size: 12 }} lg={{ size: 4 }} className="left-side">
              <p className="h4 text-center fw-bold mb-5">
                {context.t("Select a Wallet")}
              </p>
              <Nav className="flex-lg-column justify-content-center">
                <NavItem className="me-2 me-lg-0">
                  <Button
                    onClick={() => setTab(1)}
                    color="transparent"
                    id="connect-metamask-tab-button"
                    className={`text-start py-4 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
                      tab === 1 && "active"
                    }`}
                    block
                  >
                    <img
                      src={metamaskIcon}
                      alt={context.t("Metamask")}
                      width={32}
                      className="me-lg-4"
                    />
                    <span className="d-none d-lg-inline h5 fw-bold">
                      {context.t("Metamask")}
                    </span>
                  </Button>
                </NavItem>
                <NavItem className="me-2 me-lg-0">
                  <Button
                    onClick={() => setTab(3)}
                    color="transparent"
                    className={`text-start py-4 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
                      tab === 3 && "active"
                    }`}
                    block
                  >
                    <img
                      src={walletconnectIcon}
                      alt={context.t("Wallet Connect")}
                      width={32}
                      className="me-lg-4"
                    />
                    <span className="d-none d-lg-inline h5 fw-bold">
                      {context.t("Wallet Connect")}
                    </span>
                  </Button>
                </NavItem>
                <div className="mt-5 mb-3 d-none d-lg-flex px-lg-5 align-items-center text-center">
                  <span
                    className="border-bottom border-dark mx-3 mb-2"
                    style={{ width: "30px" }}
                  ></span>
                  <span className="d-none d-lg-inline h5 text-dark">
                    {context.t("Bowline Connect")}
                  </span>
                  <span
                    className="border-bottom border-dark mx-3 mb-2"
                    style={{ width: "30px" }}
                  ></span>
                </div>
                <NavItem className="me-2 me-lg-0">
                  <Button
                    onClick={() => setTab(2)}
                    className={`text-start py-4 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
                      tab === 2 && "active"
                    }`}
                    block
                    color="transparent"
                  >
                    <FcGoogle
                      alt={context.t("Google")}
                      size={32}
                      className="me-lg-4"
                    />
                    <span className="d-none d-lg-inline h5 fw-bold">
                      {context.t("Google")}
                    </span>
                  </Button>
                </NavItem>
                {/* <NavItem className="me-2 me-lg-0">
                  <Button
                    onClick={() => setTab(4)}
                    className={`text-start py-4 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
                      tab === 4 && "active"
                    }`}
                    block
                    color="transparent"
                  >
                    <BsFacebook
                      alt={context.t("Facebook")}
                      size={32}
                      className="me-lg-4"
                    />
                    <span className="d-none d-lg-inline h5 fw-bold">
                      {context.t("Facebook")}
                    </span>
                  </Button>
                </NavItem> */}
              </Nav>
            </Col>
            <Col
              xs={{ size: 12 }}
              lg={{ size: 8 }}
              className="right-side px-lg-5"
            >
              {tab === 0 && (
                <div className="px-lg-5">
                  <p className="h5 text-center fw-bold mb-4">
                    {context.t("How to connect?")}
                  </p>
                  <p className="text-center text-muted">
                    {context.t(
                      "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet."
                    )}
                  </p>
                  <p className="h5 text-center fw-bold mb-4 mt-5">
                    {context.t("I do have a wallet.")}
                  </p>
                  <p className="text-center text-muted">
                    {context.t(
                      "In case you do already have a wallet, please select MetaMask or Wallet Connect to connect to your wallet."
                    )}
                  </p>
                  <p className="h5 text-center fw-bold mb-4 mt-5">
                    {context.t("No wallet yet?")}
                  </p>
                  <p className="text-center text-muted">
                    {context.t(
                      "If you do not have a wallet yet, you can easily create a personal wallet with Bowline Connect by choosing one of the listed social login options."
                    )}
                  </p>
                </div>
              )}
              {tab === 1 && <MetamaskConnectInfo />}
              {tab === 2 && <AuthConnectButton />}
              {tab === 3 && <WalletConnectInfo />}
              {/* {tab === 4 && <FacebookConnectButton />} */}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

/** define proptypes for the ConnectWallet */
ConnectWallet.propTypes = {
  className: PropTypes.string,
  buttonId: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  block: PropTypes.bool,
};

ConnectWallet.defaultProps = {
  buttonColor: "secondary",
  buttonSize: "md",
};

/**  define proptype for the 'translation' function  */
ConnectWallet.contextTypes = {
  t: PropTypes.func,
};

export default ConnectWallet;
