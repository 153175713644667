/**
 * The BowlineWallet component
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  ModalFooter,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { useState, useEffect } from "react";
import TrunkatedAddress from "../Utils/TrunkatedAddress";
import arcticWallet from "../../assets/icons/arctic-wallet.svg";
import { AiFillCheckCircle, AiFillCopy } from "react-icons/ai";
import { MdCheckCircle, MdError } from "react-icons/md";
import { useWeb3React } from "@web3-react/core";
import Balance from "../Balance";
import WalletExport from "../WalletExport";
import Moment from "react-moment";
import {
  signRequests,
  signRequestSuccess,
  signRequestFail,
} from "../../redux/signRequests";
import { useSelector, useDispatch } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import CopyToClipboard from "react-copy-to-clipboard";
import { getName } from "../../connectors/utils";
import SignInWithEthereumButton from "../SignInWithEthereumButton";
import { transactionRequests } from "../../redux/transactionRequests";
import TransactionRequest from "../TransactionRequest";

// import { actions as walletActions } from "../../redux/wallets";
/**
 * Stateless react component to render the BowlineWallet component
 * @param {Object} props the props passed to the component.
 */
const BowlineWallet = (props, context) => {
  const { account, connector, provider } = useWeb3React();
  const signedInWithEthereum = useSelector(
    (state) => state.wallets.siweSuccess
  );
  const connectorName = getName(connector);
  const userInfo = connector ? connector.userInfo : null;
  const dispatch = useDispatch();
  // const [pendingSignature, setPendingSignature] = useState(false);
  const signaturesList = useSelector((state) => state.signRequests.list);
  const transactionsList = useSelector(transactionRequests);
  const pendingSignatures = useSelector((state) => signRequests(state));
  const pendingSignature =
    pendingSignatures && pendingSignatures.length > 0 && pendingSignatures[0];
  const [showWallet, setShowWallet] = useState(pendingSignatures?.length > 0);
  const toggleWallet = () => setShowWallet(!showWallet);

  useEffect(() => {
    if (pendingSignature && !showWallet) {
      setShowWallet(true);
    }
  }, [pendingSignature]);

  const handleSignature = async () => {
    if (pendingSignature) {
      try {
        const signer = provider.getSigner();

        const signature = await signer.signMessage(pendingSignature.message);
        dispatch(pendingSignature.successAction({ signature }));
        pendingSignature.callbackSuccess &&
          pendingSignature.callbackSuccess(signature);
        dispatch(signRequestSuccess(pendingSignature.id));
        toggleWallet();
      } catch (error) {
        dispatch(pendingSignature.failureAction(error));
      }
    }
  };

  const handleCancel = () => {
    if (pendingSignature) {
      dispatch(signRequestFail(pendingSignature.id));
      toggleWallet();
    }
  };

  const handleDisconnect = () => {
    console.log("disconnect");
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
  };

  return (
    <>
      <Button
        onClick={toggleWallet}
        color="white"
        className="position-relative"
      >
        <img src={arcticWallet} width={20} />
        <span className="sr-only">{context.t("Wallet")}</span>
        {pendingSignature && (
          <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
            <span className="visually-hidden">New alerts</span>
          </span>
        )}
        {!signedInWithEthereum && (
          <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
            <span className="visually-hidden">
              {context.t("Not signed in with ethereum")}
            </span>
          </span>
        )}
      </Button>
      <Modal
        isOpen={showWallet}
        toggle={toggleWallet}
        className="bowlinewallet__modal"
        fullscreen="md"
        scrollable
      >
        <ModalHeader toggle={toggleWallet}>
          {context.t("Bowline Wallet")}
        </ModalHeader>
        {pendingSignature ? (
          <>
            <ModalBody className="d-flex flex-column">
              <div className="d-flex justify-content-between my-3">
                <div>
                  {context.t("Account")}:<br />
                  <TrunkatedAddress address={account} />
                </div>
                <div className="text-end">
                  {context.t("Balance")}:<br />
                  {/* <span>0 ETH</span> */}
                  <Balance />
                </div>
              </div>
              <div className="d-flex flex-column flex-grow-1">
                <hr />
                <p className="h4">{context.t("Signing Request")}</p>
                {pendingSignature?.messagePreview && (
                  <div>
                    <p>{pendingSignature?.messagePreview.headline}</p>
                    <p>{pendingSignature?.messagePreview.body}</p>
                    {pendingSignature?.messagePreview?.terms?.map(
                      (term, index) => (
                        <p key={`term-${index}`}>{term}</p>
                      )
                    )}
                    <small>{pendingSignature?.messagePreview.date}</small>
                  </div>
                )}
                {pendingSignature &&
                  !pendingSignature?.messagePreview &&
                  pendingSignature.message && (
                    <p className="overflow-scroll">
                      {pendingSignature.message}
                    </p>
                  )}
              </div>
            </ModalBody>
            <ModalFooter className="">
              <div className="flex-grow-1 m-0">
                <Row>
                  <Col>
                    <Button
                      color="secondary"
                      className="w-100"
                      onClick={handleCancel}
                    >
                      {context.t("Cancel")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={handleSignature}
                    >
                      {context.t("Sign")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody className="d-flex flex-column">
              {!signedInWithEthereum ? (
                <div>
                  <p className="fw-bold">
                    {context.t("You are not signed in with ethereum yet")}
                  </p>
                  <p className="small">
                    {context.t(
                      `You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.`
                    )}
                  </p>
                  <SignInWithEthereumButton
                    color="primary"
                    block
                    size="sm"
                    outline
                    onSuccess={toggleWallet}
                  />
                </div>
              ) : (
                <div>
                  <MdCheckCircle className="text-success me-2" />
                  {context.t("You are signed in with ethereum")}
                </div>
              )}
              {userInfo && (
                <div>
                  <div className="d-flex align-items-center my-2 small">
                    {userInfo.profileImage && (
                      <img
                        src={userInfo.profileImage}
                        className="img-fluid me-2 rounded"
                        width="20"
                      />
                    )}
                    {userInfo.email && <span>{userInfo.email}</span>}
                    {userInfo.typeOfLogin && userInfo.typeOfLogin === "google" && (
                      <span className="ms-5 d-flex align-items-center">
                        Logged in <FcGoogle className="ms-1" />
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-between my-3">
                <div>
                  {context.t("Account")}:<br />
                  <TrunkatedAddress address={account} />
                  <CopyToClipboard text={account}>
                    <Button
                      color="outline-primary"
                      size="sm"
                      className="ms-2 p-0"
                      id="copyAddressButton"
                    >
                      <AiFillCopy />
                    </Button>
                  </CopyToClipboard>
                  <UncontrolledTooltip
                    placement="right"
                    className="text-light bg-dark"
                    target="copyAddressButton"
                  >
                    {context.t("Copy address")}
                  </UncontrolledTooltip>
                </div>
                <div className="text-start">
                  {context.t("Balance")}:<br />
                  <Balance />
                </div>
              </div>
              {connectorName === "BowlineWallet" && (
                <div className="d-flex flex-column flex-grow-1 align-items-center">
                  <WalletExport />
                </div>
              )}
              {signaturesList.length > 0 && (
                <h5 className="mt-4">Recent Signatures: </h5>
              )}
              {signaturesList
                .map((signature) => (
                  <div
                    key={signature.id}
                    className="d-flex justify-content-between my-2 border border-primary p-2 rounded"
                  >
                    <span>{signature.signaturePreview}</span>
                    <span>
                      <Moment fromNow>{signature.createdAt}</Moment>
                    </span>
                    {signature?.signRequestStatus === "success" ? (
                      <Badge size="sm" color="primary" className="ms-3">
                        <AiFillCheckCircle />
                      </Badge>
                    ) : (
                      <Badge size="sm" color="info" className="ms-3">
                        <MdError />
                      </Badge>
                    )}

                    {/* <span>{signature.createdAt}</span> */}
                  </div>
                ))
                .reverse()}

              {transactionsList.length > 0 && (
                <h5 className="mt-4">Bought Transactions: </h5>
              )}
              {transactionsList
                .map((transaction) => (
                  <TransactionRequest
                    transaction={transaction}
                    key={transaction.id}
                  />
                ))
                .reverse()}
            </ModalBody>

            <ModalFooter className="">
              <div className="flex-grow-1 m-0 bg">
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={handleDisconnect}
                    >
                      {context.t("Disconnect")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

/**  define proptype for the 'translation' function  */
BowlineWallet.contextTypes = {
  t: PropTypes.func,
};

BowlineWallet.propTypes = {};

export default BowlineWallet;
