export const translations = {
  'de': {
    'Name': '',
    'Description': '',
    'Submit': '',
    'Items.header': '',
    'Items.header.offline': '',
    'YYYY-MM-DD': '',
    'LoadingContent.message': '',
    'Email': '',
    'Password': '',
    'ForgotPassword': '',
    'ForgotPassword.here': '',
    'NewPassword': '',
    'NewPasswordConfirmation': '',
    'PasswordConfirmation': '',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': '',
    'Home.header.offline': '',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Details': 'Details',
    'Metamask': 'Metamask',
    'Recommended': 'Recommended',
    'Connect to your MetaMask Wallet': 'Connect to your MetaMask Wallet',
    'LoadingContent.message': 'Loading',
    'Bowline': 'Bowline',
    'Connect to Wallet': 'Connect to Wallet',
    'A Brand for the metaverse. Built by the community. view the col....': 'A Brand for the metaverse. Built by the community. view the col….',
    'Some quick example text to build on the card title and make up the bulk of the card\'s content.': 'Some quick example text to build on the card title and make up the bulk of the card’s content.',
    'If you want to sign in for "this Early Access", please connect your wallet!': 'If you want to sign in for “this Early Access”, please connect your wallet!',
    'Connect Wallet': 'Connect Wallet',
    'Back': 'Back',
    'Email': 'Email',
    'Name': 'Name',
    'Password': 'Password',
    'Password (confirmation)': 'Password (confirmation)',
    'Submit': 'Submit',
    'Share': 'Share',
    'a content category': 'a content category',
    'an unlockable content': 'an unlockable content',
    'an Early Access entry': 'an Early Access entry',
    'a event': 'a event',
    'created': 'created',
    'updated': 'updated',
    'Success!': 'Success!',
    'You successfully {action} {resourceType}': 'You successfully {action} {resourceType}',
    'ok': 'ok',
    'click to get back to overview screen': 'click to get back to overview screen',
    'Missing Name': 'Missing Name',
    'Select': 'Select',
    'Selected': 'Selected',
    'Select Token for Unlocking': 'Select Token for Unlocking',
    'Sorry you dont have any matching Token for unlocking': 'Sorry you don’t have any matching Token for unlocking',
    'Cancel': 'Cancel',
    'Unlock': 'Unlock',
    'Sign Up': 'Sign Up',
    'Change password': 'Change password',
    'Insert your current password and the new password': 'Insert your current password and the new password',
    'Current Password': 'Current Password',
    'Save': 'Save',
    'community event': 'community event',
    'partner event': 'partner event',
    'conference': 'conference',
    'irl meetup': 'irl meetup',
    'irl party': 'irl party',
    'virtual meetup': 'virtual meetup',
    'discord event': 'discord event',
    'ask me anything': 'ask me anything',
    'Select a Wallet': 'Select a Wallet',
    'Don\'t have a wallet yet? See here how to create it': 'Don’t have a wallet yet? See here how to create it',
    'Terms and conditions': 'Terms and conditions',
    'Contract Address': 'Contract Address',
    'Add my Wallet to this Early Access': 'Add my Wallet to this Early Access',
    'Congratulations!': 'Congratulations!',
    'You\'ve been added to the Early Access!': 'You’ve been added to the Early Access!',
    'Do you want to receive notifications on your email about this listing?': 'Do you want to receive notifications on your email about this listing?',
    'Wallet Address': 'Wallet Address',
    'You are already part of this Early Access!': 'You are already part of this Early Access!',
    'Bowline public app demo': 'Bowline public app demo',
    'show': 'show',
    'Events': 'Events',
    'Unlockables': 'Unlockables',
    'Votings': 'Votings',
    'Early Access': 'Early Access',
    'Token Search': 'Token Search',
    'My Tokens': 'My Tokens',
    'Missing title': 'Missing title',
    'Event Type': 'Event Type',
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    'Sign up for this event': 'Sign up for this event',
    'There was a problem listing all the unlockable contents. Please contact support on discord': 'There was a problem listing all the unlockable contents. Please contact support on discord',
    'Page not found': 'Page not found',
    'Back to Home': 'Back to Home',
    'Metamask connect': 'Metamask connect',
    'Onboarding': 'Onboarding',
    'tokens': 'tokens',
    'Missing name': 'Missing name',
    'View external item': 'View external item',
    'Check out this Token': 'Check out this Token',
    'Description': 'Description',
    'Owner': 'Owner',
    '(You own this token)': '(You own this token)',
    'Traits': 'Traits',
    'Blockchain': 'Blockchain',
    'Token ID': 'Token ID',
    'Token Standard': 'Token Standard',
    'Check on Marketplaces': 'Check on Marketplaces',
    'Rarible': 'Rarible',
    'OpenSea': 'OpenSea',
    'LooksRare': 'LooksRare',
    'Secret Description': 'Secret Description',
    'Unlockable Contents': 'Unlockable Contents',
    'There are no unlockable contents yet': 'There are no unlockable contents yet',
    'You will receive a mail with a link to the password reset page.': 'You will receive a mail with a link to the password reset page.',
    'immediate': 'immediate',
    'after vote': 'after vote',
    'after end': 'after end',
    'Reveal Type': 'Reveal Type',
    'There are no votings options available': 'There are no votings options available',
    'vote': 'vote',
    'There was a problem showing this voting. Please contact support on discord': 'There was a problem showing this voting. Please contact support on discord',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao 1 Kind',
    'Items.header.offline': 'Ciao offline 1 Kind',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao 1 Kind',
    'Home.header.offline': 'Ciao offline 1 Kind',
  },
}
