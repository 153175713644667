/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import notificationsSaga from "./notifications/sagas";
import { all } from "redux-saga/effects";
import accountSaga from "./account/sagas";
import dictionarySaga from "./dictionarySagas";
import errorsSaga from "./errorsSagas";
import serviceWorkerSaga from "./servWork/sagas";
import projectSaga from "./projects/sagas";
import whitelistWalletsSaga from "./whitelistWallets/sagas";
import eventRegistrationsSaga from "./eventRegistrations/sagas";
import walletSaga from "./wallets/sagas";
import creatorSaga from "./creators/sagas";
import holderSaga from "./holders/sagas";
import tokensSaga from "./tokens/sagas";
import tokenHistoryEventsSaga from "./tokenHistoryEvents/sagas";
import unlockableContentsSaga from "./unlockableContents/sagas";
import contentCategoriesSaga from "./contentCategories/sagas";
import contractsSaga from "./contracts/sagas";
import votingsSaga from "./votings/sagas";
import votingOptionSaga from "./votingOptions/sagas";
import partnerDealsSaga from "./partnerDeals/sagas";
import eventsSaga from "./events/sagas";
import unlockBoundariesSaga from "./unlockBoundaries/sagas";
import unlockInputsSaga from "./unlockInputs/sagas";
import traitTypesSaga from "./traitTypes/sagas";
import traitsSaga from "./traitTypes/sagas";
import mintTierSaga from "./mintTiers/sagas";
import bottleAliasesSaga from "./bottleservice/aliases/sagas";

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    notificationsSaga(),
    dictionarySaga(),
    errorsSaga(),
    accountSaga(),
    serviceWorkerSaga(),
    holderSaga(),
    projectSaga(),
    whitelistWalletsSaga(),
    walletSaga(),
    creatorSaga(),
    tokensSaga(),
    unlockableContentsSaga(),
    contentCategoriesSaga(),
    contractsSaga(),
    partnerDealsSaga(),
    votingsSaga(),
    eventRegistrationsSaga(),
    votingOptionSaga(),
    eventsSaga(),
    unlockInputsSaga(),
    tokenHistoryEventsSaga(),
    unlockBoundariesSaga(),
    traitTypesSaga(),
    traitsSaga(),
    mintTierSaga(),
    bottleAliasesSaga(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
  ]);
}
