/**
 * Normalize ENVs
 */

function isTrue(value) {
  if (typeof value === "string") {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}

export const {
  REACT_APP_API_SERVER,
  REACT_APP_CDN_SERVER,
  REACT_APP_PROJECT_ID,
  REACT_APP_BOTTLE_SERVICE_API_SERVER,
  REACT_APP_WEB3_AUTH_ID,
  REACT_APP_WEB_SERVER,
  REACT_APP_WERT_PARTNER_ID,
  REACT_APP_WERT_COMMODITY,
  REACT_APP_WERT_ORIGIN,
  REACT_APP_FB_ID,
  REACT_APP_FB_VERIFIER,
  REACT_APP_CDN_PATH,
  REACT_APP_GTM,
  REACT_APP_GOOGLE_ID,
  REACT_APP_GOOGLE_VERIFIER,
  REACT_APP_INFURA_ID,
  REACT_APP_FIAT_FORWARDER_ADDRESS,
  REACT_APP_ARCTIC_REDEMPTION_UNLOCKABLE,
  REACT_APP_ARCTIC_DISCOUNT_TIER,
  REACT_APP_ARCTIC_SHOP_URL,
} = typeof window !== "undefined" ? window.env : process.env;

export const REACT_APP_MAINNET =
  typeof window !== "undefined"
    ? isTrue(window.env.REACT_APP_MAINNET)
    : isTrue(process.env.REACT_APP_MAINNET);
