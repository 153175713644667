/** Worker Sagas */

import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import { actions as traitActions } from "../traits";
import { actions as signRequestActions } from "../signRequests/index";
import { v4 as uuidv4 } from "uuid";
import { getName } from "../../connectors/utils";
import { handleUnauthorized } from "../errorsSagas";
/** List Saga
 *  @description: connects to the getUnlockableContent operation
 */
export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum, sorting, filters } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.UnlockableContents.listUnlockableContentsForProject,
      {
        project_id: projectId,
        page: pageNum,
        items: itemNum,
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
    if (e.status === 403) {
      yield* handleUnauthorized({ payload: e});
    }
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { project_id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.UnlockableContents.createUnlockableContentForProject,
      { project_id: project_id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}
/** Show Saga
 *  @description: connects to the showUnlockableContent operation
 *  @param {number} action.payload the UnlockableContent id
 */
export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, id, ...options } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.UnlockableContents.showUnlockableContentForProject,
      {
        project_id: projectId,
        id: id,
        ...options,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
    if (e.status === 403) {
      yield* handleUnauthorized({ payload: e});
    }
  }
}

export function* update(action) {
  const { project_id, id, ...requestBody } = action.payload;
  let headers = yield buildHeaders();
  let includedTraitsDictionary = {};
  let remainingIncluded = [];
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.UnlockableContents.updateUnlockableContentForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      payload.obj.included.forEach((entry) => {
        if (entry.type === "trait") {
          includedTraitsDictionary[entry.id] = entry;
        } else {
          remainingIncluded.push(entry);
        }
      });
    yield* storeIncluded({ payload: remainingIncluded });
    yield put({
      type: traitActions.mergeDictionary,
      payload: includedTraitsDictionary,
    });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

export function* unlock(action) {
  let headers = yield buildHeaders();
  let {
    project_id,
    id,
    token_identifier,
    contract_address,
    unlock_boundary_id,
    unlock_input_values,
    signature,
    callbackSuccess,
  } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.UnlockableContents.unlockUnlockableContentForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          signature,
          tokens_attributes: [
            {
              identifier: token_identifier,
              contract_address,
            },
          ],
          unlock_boundary_id: unlock_boundary_id,
          unlock: {
            signature,
            tokens_attributes: [
              {
                identifier: token_identifier,
                contract_address,
              },
            ],
            unlock_boundary_id: unlock_boundary_id,
            unlock_input_values: unlock_input_values,
          },
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    // TODO: reevaluate if showSuccess is right to call here, but doesn't feel to wrong to store the data
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
    yield put({ type: actions.unlockSuccess, payload: payload.obj.data });
    if (callbackSuccess) callbackSuccess();
  } catch (e) {
    yield put({ type: actions.unlockFail, payload: e });
  }
}

export function* prepareUnlock(action) {
  let {
    account,
    connector,
    provider,
    token_identifier,
    unlock_boundary_id,
    project_title,
    contract_address,
    title,
    callbackSuccess,
  } = action.payload;
  try {
    //  Maybe lets add an issued at to the message and use this date also for unlocked_at in the api
    const signer = provider.getSigner();
    const message = `I want to unlock "${title}" on ${project_title}
with my NFT #${token_identifier} (Contract: ${contract_address}).

This message was signed with my Ethereum account:
${account}

URI: https://bowline.app
Version: 1
Chain ID: 1
`;

    console.log("message", message);
    const name = getName(connector);

    if (name !== "BowlineWallet") {
      const signature = yield signer.signMessage(message);

      yield put({ type: actions.prepareUnlockSuccess, payload: {} });
      callbackSuccess({
        signature,
        token_identifier,
        contract_address,
        unlock_boundary_id,
      });
    } else {
      console.log("Signing with bowline wallet");
      let signaturePreview = "Unlock Content";
      let messagePreview = {
        headline: `I want to unlock "${title}" on ${project_title} with my NFT #${token_identifier} (Contract: ${contract_address}).`,
        body: `This message was signed with my Ethereum account: ${account}`,
        terms: [`URI: https://bowline.app`, `Version: 1`, `Chain ID: 1`],
      };
      yield put({
        type: signRequestActions.addToDictionary,
        payload: {
          id: uuidv4(),
          message,
          signer,
          signaturePreview,
          messagePreview,
          callbackSuccess: (sig) =>
            callbackSuccess({
              token_identifier,
              contract_address,
              unlock_boundary_id,
              signature: sig,
            }),
          successAction: actions.prepareUnlockSuccess,
          failureAction: actions.prepareUnlockFail,
        },
      });
    }
  } catch (e) {
    yield put({ type: actions.prepareUnlockFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* unlockableContentsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.prepareUnlock, prepareUnlock);
  yield takeEvery(actions.unlock, unlock);
}
export default unlockableContentsSaga;
