/**
 * The Root container
 */
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Loadable from "react-loadable";
import { useSsrEffect } from "@issr/core";
import { useWeb3React } from "@web3-react/core";
import FourOFour from "./FourOFour";
import Navbar from "../components/Navbar";
import { isServer } from "../redux/store";
import ProjectOnboarding from "../components/ProjectOnboarding";
import { showProject } from "../redux/projects";
import { REACT_APP_PROJECT_ID } from "../utils/env";
import PrivateRouteContainer from "../components/PrivateRoute/PrivateRoute-container";

/**
 * Loadable import for Home container
 */

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ "./Home"),
  loading: () => null,
  modules: ["Home"],
});

const Whitelist = Loadable({
  loader: () => import(/* webpackChunkName: "Whitelist" */ "./Whitelist"),
  loading: () => null,
  modules: ["Whitelist"],
});

const EventsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "EventsRoot" */ "./Events/EventsRoot"),
  loading: () => null,
  modules: ["EventsRoot"],
});

const VotingsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "VotingsRoot" */ "./Votings/VotingsRoot"),
  loading: () => null,
  modules: ["Votings"],
});

const TokensRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TokensRoot" */ "./Tokens/TokensRoot"),
  loading: () => null,
  modules: ["TokensRoot"],
});

const MintingSectionRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MintingSection" */ "./Minting/MintingSectionRoot"
    ),
  loading: () => null,
  modules: ["MintingSection"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
function Root() {
  const { account } = useWeb3React();
  if (!isServer) window.account = account;

  const dispatch = useDispatch();
  const dispatchShowProject = async () => {
    dispatch(showProject(REACT_APP_PROJECT_ID));
  };

  useSsrEffect(dispatchShowProject);
  useEffect(dispatchShowProject, [dispatch, REACT_APP_PROJECT_ID]);

  return (
    <StickyContainer id="Root">
      <Navbar />
      {account && <ProjectOnboarding />}
      <div id="content">
        <div id="randomTest"></div>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path={"/earlyaccess"} component={Whitelist} />
          <PrivateRouteContainer path="/events" component={EventsRoot} />
          <PrivateRouteContainer path={"/votings"} component={VotingsRoot} />
          <PrivateRouteContainer path="/tokens" component={TokensRoot} />
          <Route path="/purchase" component={MintingSectionRoot} />

          {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
          {/* new routes */}

          <Route component={FourOFour} />
        </Switch>
      </div>
    </StickyContainer>
  );
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = () => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
