/**
 * The navbar container
 */
import React from "react";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { Collapse, Navbar, NavbarToggler } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import FlashAlert from "../FlashAlert";
import { NavLink } from "react-router-dom";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";
import arcticLogo from "../../assets/images/arctic-logo-black.png";
import BowlineWallet from "../BowlineWallet";
import ConnectWallet from "../ConnectWallet";
import arcticWallet from "../../assets/icons/arctic-wallet.svg";
import { useState } from "react";

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  alertOrigin: state.notifications.origin,
  alertLink: state.notifications.link,
  lang: state.i18nState.lang,
  currentUser: state.account.currentUser,
  signedin: state.account.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      navigateTo: push,
    },
    dispatch
  );

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props, context) => {
  const { alertMessage, alertColor, alertTimeout, alertLink, alertOrigin } =
    props;
  const { account } = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      style={props.style}
      className={classNames("navigationbar ", {
        "navigationbar--sticky": props.isSticky,
      })}
    >
      <Navbar
        color="white"
        expand="md"
        className="navigationbar__navbar p-0 position-relative"
      >
        <NavbarToggler className="navbar-light" onClick={toggle} />
        <NavLink to="/" className="navigationbar__logo">
          <img src={arcticLogo} alt="arctic-logo" height={40} width={89} />
        </NavLink>
        <Collapse navbar isOpen={isOpen}>
          <div className="ms-sm-3 mb-3 mb-sm-0">
            <a
              href="https://www.facebook.com/arcticbluebeverages"
              target="_blank"
              className="me-3 p-2"
              rel="noreferrer"
            >
              <BsFacebook size={18} />
            </a>
            <a
              href="https://www.instagram.com/arcticbluebeverages/"
              target="_blank"
              className="me-3 p-2"
              rel="noreferrer"
            >
              <BsInstagram size={18} />
            </a>
            <a
              href="https://www.linkedin.com/company/arcticbluebeverages/"
              target="_blank"
              className="me-3 p-2"
              rel="noreferrer"
            >
              <BsLinkedin size={18} />
            </a>
          </div>
          <div className="ms-sm-auto align-self-start ms-3">
            <NavLink
              to="/purchase"
              exact
              className="me-sm-3 text-decoration-none d-block d-sm-inline mb-3 mb-sm-0"
              onClick={() => setIsOpen(false)}
            >
              {context.t("Purchase")}
            </NavLink>
            <NavLink
              to="/"
              exact
              className="me-sm-3 text-decoration-none d-block d-sm-inline mb-3 mb-sm-0"
              onClick={() => setIsOpen(false)}
            >
              {context.t("Holders' Area")}
            </NavLink>
          </div>
        </Collapse>
        {!account ? (
          <ConnectWallet
            buttonColor="link"
            buttonId="nav-connect-wallet"
            className="text-decoration-none"
            buttonLabel={
              <>
                <span className="d-none d-sm-inline">
                  {context.t("Sign in")}
                </span>
                <img src={arcticWallet} width={20} className="ms-3" />
              </>
            }
          />
        ) : (
          <BowlineWallet />
        )}
      </Navbar>
      {alertMessage && (
        <FlashAlert
          color={alertColor || "success"}
          timeout={alertTimeout}
          origin={alertOrigin}
          link={alertLink}
        >
          {alertMessage}
        </FlashAlert>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

Navigationbar.propTypes = {
  navigateTo: PropTypes.func,
  handleLogoutAction: PropTypes.func,
  lang: PropTypes.string,
  currentUser: PropTypes.object,
  signedin: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertColor: PropTypes.string,
  alertTimeout: PropTypes.bool,
  isSticky: PropTypes.bool,
  style: PropTypes.object,
  alertLink: PropTypes.string,
  alertOrigin: PropTypes.string,
};

Navigationbar.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar);
